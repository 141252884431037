<template>
	<div
		class="bg-error full-height"
	>
		
		<div 
			class="full-height flex-column justify-center line-height-200"
		>
			<div class="text-center">
				<v-icon
					class="color-red  size-px-48"
				>mdi-alert-outline</v-icon>
			</div>
			<p class="size-em-20 ptb-20">존재하지 않는 페이지입니다</p>
			<p class="size-em-20">확인 후 다시 이용해주세요.</p>
			<p class="size-em-20 cursor-pointer mt-30 " @click="$emit('push', { name: 'Index'})"><v-icon class="color-white size-px-32 vertical-middle mb-4">mdi mdi-arrow-left-bold-box-outline</v-icon> 돌아가기</p>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'ErrorNetwork'
		,created: function(){
            this.$layout = this.$root.$children[0].$children[0]
            this.$layout.onLoad(this.program)
		}
		,data: function(){
			return {
				program: {
					title: 'Not Found Page'
					,not_header: true
					,not_side: true
				}
			}
		}
	}
</script>

<style>
	.bg-error {background-color: #15324E !important; color: white; text-align: center;}
	.fill-height {height: 100%; width: 100%;}
</style>